import React, { useEffect, useState } from 'react';
import { getDocs, collection } from 'firebase/firestore';
import { db } from '../firebase';
import { Link } from 'react-router-dom';
import { TableCustomerScoreDoughnut } from '../Charts/CustomerScoreDoughnut';

export default function ScoreTable() {
  const [allCustomers, setAllCustomers] = useState([]);
  const [sortOrder, setSortOrder] = useState('desc');  // Initialized to 'desc' to show highest scores first
  
  useEffect(() => {
    const fetchData = async () => {
      const fetchedCustomers = await fetchAllCustomers();
      setAllCustomers(fetchedCustomers);
    };
    fetchData();
  }, []);

  const fetchAllCustomers = async () => {
    const querySnapshot = await getDocs(collection(db, 'customers'));
    return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  };

  const sortedCustomers = [...allCustomers].sort((a, b) => {
    return sortOrder === 'asc' ? a.score - b.score : b.score - a.score;
  });

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  return (
    <div>
      <h2>Customer Scores</h2>
      <table className="dashboardTable">
        <thead>
          <tr>
            <th>Customer Name</th>
            <th onClick={toggleSortOrder}> Customer Score {sortOrder === 'asc' ? '▲' : '▼'} </th>
            <th>Visual</th>
          </tr>
        </thead>
        <tbody>
          {sortedCustomers.map((customer) => (
            <tr key={customer.id}>
              <td><Link to={`/customer/${customer.name}`}>{customer.name}</Link></td>
              <td>{customer.score}</td>
              <td>
                <div style={{width: '100px'}}>
                    <TableCustomerScoreDoughnut score={customer.score} />
                </div>
               </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
