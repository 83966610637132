// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import {getAuth} from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries



// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCHS8ExlHzbjKiwKthMV-IdI2iWm9QNf9g",
  authDomain: "fs-crm-ed0f1.firebaseapp.com",
  projectId: "fs-crm-ed0f1",
  storageBucket: "fs-crm-ed0f1.appspot.com",
  messagingSenderId: "237332902978",
  appId: "1:237332902978:web:936354e20b58b3f5d169db"
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const functions = getFunctions(app);
const auth = getAuth(app);
export { app, db, auth };

/* Use emulator in development mode (Uncomment below if statement)
if (process.env.NODE_ENV === 'development') {
  connectFunctionsEmulator(functions, "localhost", 5001);
  console.log(process.env.NODE_ENV)
}*/