import Chart from 'chart.js/auto';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';


export const CustomerScoreDoughnut = ({ score }) => {
  // Calculate the remaining value
  const remaining = 100 - score;

  const data = {
    labels: ['Page 1 Keywords', 'Not Ranking'],
    datasets: [
      {
        data: [score, remaining],
        backgroundColor: ['#22b31b', '#d12819'],
        hoverBackgroundColor: ['#141414', '#141414']
      }
    ]
  };

  const options = {
    elements: {
      arc: {
        hoverBorderColor: '#81099c',
        hoverBorderWidth: 3
      }
    }
  };

  return (
    <div>
      <Doughnut data={data} />
    </div>
  );
};

export const TableCustomerScoreDoughnut = ({ score }) => {
  const remaining = 100 - score;

  const data = {
    datasets: [
      {
        data: [score, remaining],
        backgroundColor: ['#22b31b', '#d12819'],
        hoverBackgroundColor: ['#141414', '#141414']
      }
    ]
  };

  const options = {
    legend: {
      display: false
    }
  };

  return (
    <div>
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default CustomerScoreDoughnut;
