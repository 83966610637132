import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, updateDoc } from 'firebase/firestore';
import { doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';

import { Link } from 'react-router-dom';

import '../CSS/Customers.css';

function Customers() {
  const [customers, setCustomers] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [monthlyBudget, setMonthlyBudget] = useState('');
  const [url, setUrl] = useState('');
  const [keywords, setKeywords] = useState('');

  const [searchTerm, setSearchTerm] = useState('');

  const [selectAll, setSelectAll] = useState(false);

  //New customer select state
  const [selectedCustomers, setSelectedCustomers] = useState([]);


  /*EDIT CODE START*/

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editCustomerData, setEditCustomerData] = useState({
    id: '',
    name: '',
    startDate: '',
    monthlyBudget: '',
    url: '',
  });

  const openEditModal = (customer) => {
    // Check if startDate has toDate function available before calling it
    let formattedStartDate;
    if (customer.startDate && typeof customer.startDate.toDate === 'function') {
      formattedStartDate = customer.startDate.toDate().toISOString().substring(0, 10);
    } else if (customer.startDate instanceof Date) {
      // If startDate is already a Date object
      formattedStartDate = customer.startDate.toISOString().substring(0, 10);
    } else {
      // If startDate is in a different format or undefined, handle accordingly
      formattedStartDate = ''; // Or you can set a default value or perform other logic
    }
  
    setEditCustomerData({
      id: customer.id,
      name: customer.name,
      startDate: formattedStartDate,
      monthlyBudget: customer.monthlyBudget.toString(),
      url: customer.url,
    });
    setIsEditModalOpen(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditCustomerData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const updateCustomer = async () => {
    const { id, ...updatedData } = editCustomerData;
    updatedData.monthlyBudget = parseFloat(updatedData.monthlyBudget);
    updatedData.startDate = new Date(updatedData.startDate);
  
    try {
      // Assuming you have a `updateDoc` imported from Firebase
      await updateDoc(doc(db, 'customers', id), updatedData);
      setIsEditModalOpen(false);
      fetchData();
    } catch (error) {
      console.error('Error updating customer:', error);
    }
  };
  
  /*EDIT CODE START HERE*/
  



  const fetchData = async () => {
    const querySnapshot = await getDocs(collection(db, 'customers'));
    const customersList = [];
    querySnapshot.forEach((doc) => {
      customersList.push({
        id: doc.id,
        ...doc.data(),
        startDate: doc.data().startDate.toDate() // Convert Firestore Timestamp to JavaScript Date
      });
    });
    setCustomers(customersList);
  };

  useEffect(() => {
    fetchData();
  }, []);

  


  const addCustomer = async () => {
    try {
      const customer = {
        name,
        startDate: new Date(startDate), // Convert to date object
        monthlyBudget: parseFloat(monthlyBudget), // Convert to number
        url,
        keywords: keywords.split(',').map(keyword => ({ keyword, position: 0 })), // Split keywords by comma and create array of maps
        score: 0
      };
      await addDoc(collection(db, 'customers'), customer);
      setShowModal(false); // Close the modal
      fetchData();
    } catch (e) {
      console.error('Error adding document:', e);
    }
  };

  const deleteCustomer = async (id) => {
    if (window.confirm('Are you sure you want to delete this customer?')) {
      await deleteDoc(doc(db, 'customers', id));
      fetchData(); // Refresh the data after deleting
    }
  };


  const editCustomer = (customer) =>{
    // Logic to edit customer
  }

  const toggleSelectCustomer = (customerId) => {
    setSelectedCustomers(prevSelected => {
      if (prevSelected.includes(customerId)) {
        // Remove customerId from selectedCustomers if it's already selected
        return prevSelected.filter(id => id !== customerId);
      } else {
        // Add customerId to selectedCustomers if it's not already selected
        return [...prevSelected, customerId];
      }
    });
  };
  


  const updateKeywordPositions = async () => {
    const functionUrl = 'https://us-central1-fs-crm-ed0f1.cloudfunctions.net/updateKeywordPositions';
  
    try {
      const response = await fetch(functionUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ customerIds: selectedCustomers }),  // send selected customer IDs
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log('Operation completed: ', data);
        data.results.forEach(result => {
          if (result.status === 'Successful'){
          console.log(`Customer: ${result.customer}, Keyword: ${result.keyword}, Status: Successful, Position: ${result.position}`);
        } else {
          console.log(`Customer: ${result.customer}, Keyword: ${result.keyword}, Status: Failed, Reason: ${result.reason}`);
        }
      });
        alert('Keyword positions updated successfully.');
      } else {
        console.log('Failed to update keyword positions:', response.status, response.statusText);
        alert('Failed to update keyword positions.');
      }
    } catch (error) {
      console.error('Error updating keyword positions:', error);
      alert('An error occurred. Please try again.');
    }
  };

  async function callUpdateCustomerScores(){
    try {
      const response = await fetch("https://us-central1-fs-crm-ed0f1.cloudfunctions.net/manualUpdateCustomerScores", { //https://us-central1-fs-crm-ed0f1.cloudfunctions.net/manualUpdateCustomerScores
        method: 'GET', // or 'GET', depending on your Cloud Function
      });
      const data = await response.text();
      console.log('Success:', data);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const queueUpdates = async () => {
    for (const customerId of selectedCustomers) {
      // Add each selected customer to the updateQueue collection.
      await addDoc(collection(db, 'updateQueue'), { id: customerId });
    }
    alert('Keyword update process initiated.');
  };


  async function updateScores() {
    try {
        const response = await fetch('https://us-central1-fs-crm-ed0f1.cloudfunctions.net/manualUpdateCustomerScores', {
            method: 'POST',
        });
        const result = await response.text();
        console.log(result);
    } catch (error) {
        console.error('Error updating scores:', error);
    }
}

  //Function to select all customers
  const toggleSelectAllCustomers = (customerId) => {
    setSelectedCustomers(prevSelected => {
      if (prevSelected.includes(customerId)) {
        const newSelected = prevSelected.filter(id => id !== customerId);
        if (newSelected.length !== customers.length) {
          setSelectAll(false);
        }
        return newSelected;
      } else {
        const newSelected = [...prevSelected, customerId];
        if (newSelected.length === customers.length) {
          setSelectAll(true);
        }
        return newSelected;
      }
    });
  };




  return (
    <div>
      <h1>Customers</h1>
      <button onClick={() => setShowModal(true)}>Add new customer</button>
      <button onClick={queueUpdates}>Add Customers to Queue and Update Positions</button>
      <button onClick={updateKeywordPositions}>Manually Update Keyword Positions (Max. 3)</button>
      <button onClick={updateScores}>Refresh Customer Scores</button>


      {isEditModalOpen && (
        <div className="form-container">
          <h2>Edit Customer</h2>
          <label>Name: <input type="text" name="name" value={editCustomerData.name} onChange={handleEditChange} /></label>
          <label>Start Date: <input type="date" name="startDate" value={editCustomerData.startDate} onChange={handleEditChange} /></label>
          <label>Monthly Budget: <input type="number" name="monthlyBudget" value={editCustomerData.monthlyBudget} onChange={handleEditChange} /></label>
          <label>URL: <input type="text" name="url" value={editCustomerData.url} onChange={handleEditChange} /></label>
          <button onClick={updateCustomer}>Update</button>
          <button onClick={() => setIsEditModalOpen(false)}>Cancel</button>
        </div>
      )}

    

      {showModal && (
        <div className="form-container">
          <h2>Add Customer</h2>
          <label>Name: <input type="text" value={name} onChange={e => setName(e.target.value)} /></label>
          <label>Start Date: <input type="date" value={startDate} onChange={e => setStartDate(e.target.value)} /></label>
          <label>Monthly Budget: <input type="number" value={monthlyBudget} onChange={e => setMonthlyBudget(e.target.value)} /></label>
          <label>URL: <input type="text" value={url} onChange={e => setUrl(e.target.value)} /></label>
          <label>Keywords: <input type="text" value={keywords} onChange={e => setKeywords(e.target.value)} /></label>
          <button onClick={addCustomer}>Save</button>
          <button onClick={() => setShowModal(false)}>Cancel</button>
        </div>
      )}

      <br />
      <input type="text" placeholder="Search by name..." value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />


      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Start Date</th>
            <th>Spend</th>
            <th>Controls</th>
            <th>Mark for Keyword Update <br /> <label>Select All</label> <input 
              type="checkbox" 
              checked={selectAll} 
              onChange={() => {
                const newSelectAllStatus = !selectAll;
                setSelectAll(newSelectAllStatus);
                if (newSelectAllStatus) {
                  setSelectedCustomers(customers.map(customer => customer.id));
                } else {
                  setSelectedCustomers([]);
                }
              }}
            />
            </th>
            <th>Last Updated</th>

          </tr>
        </thead>
        <tbody>
          {customers.filter(customer => customer.name.toLowerCase().includes(searchTerm.toLowerCase())).map((customer) => (
            <tr key={customer.name}>
              <td>{customer.name}</td>
              <td>{customer.startDate.toLocaleDateString()}</td>
              <td>{customer.monthlyBudget}</td>
              <td>
                <button onClick={() => openEditModal(customer)}>Edit</button>
                <button onClick={() => deleteCustomer(customer.id)}>Delete</button>
                <Link to={`/customer/${customer.name}`}><button>View</button></Link>
              </td>
              <td>
                <input 
                  type="checkbox" 
                  checked={selectedCustomers.includes(customer.id)} 
                  onChange={() => toggleSelectAllCustomers(customer.id)} 
                />
              </td>

              <td>{customer.updatedDate ? new Date(customer.updatedDate.seconds * 1000).toLocaleDateString() : 'Not updated yet'}</td>


            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Customers;

