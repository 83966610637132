import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { auth, db } from './firebase';
import { doc } from 'firebase/firestore';
import logo from './Images/FS Logo.png';
import { onSnapshot} from 'firebase/firestore';

import './CSS/Layout.css';


import {FaUser} from 'react-icons/fa';


function Layout({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userName, setUserName] = useState('User');

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const unsubscribeSnapshot = onSnapshot(userRef, (userDoc) => {
          if (userDoc.exists()) {
            setUserName(userDoc.data().name || 'User');
          }
        });
        
        // This will unsubscribe from the Firestore snapshot when the auth state changes
        return () => unsubscribeSnapshot();
      }
    });
  
    // This will unsubscribe from the Auth state change when the component unmounts
    return () => unsubscribeAuth();
  }, []);


  return (
    <div style={{ display: 'flex' }}>
      <div className="sidebar">
        <div className="header">
          <img src={logo} alt="Logo" />
        </div>
        <nav>
          <ul>
            <li><Link to="/">Dashboard</Link></li>
            <li><Link to="/customers">Customers</Link></li>
          </ul>
        </nav>
  
        {currentUser &&
          <div className="user-greeting">
            <Link to="/profile">
              <span>Hi, {userName} </span>
              <span><FaUser /></span>
            </Link>
          </div>
        }
      </div>
      <div className="content-area">
        {children}
      </div>
    </div>
  );
}

export default Layout;
