import React, { useState, useEffect, useContext } from 'react';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { AuthContext } from '../AuthContext';
import { db } from '../firebase';

const Profile = () => {
  const { user } = useContext(AuthContext);
  const [name, setName] = useState("");

  useEffect(() => {
    if (user) {
      const fetchProfile = async () => {
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setName(userDoc.data().name || "");
        }
      };

      fetchProfile();
    }
  }, [user]);

  const handleUpdate = async () => {
    if (user) {
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, { name });
    }
  };

  return (
    <div>
      <h1>My Profile</h1>
      <div>
        <label>
          Name:
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
        <button onClick={handleUpdate}>Update</button>
      </div>
    </div>
  );
};

export default Profile;