import React from "react";
import DateTable from "../Components/dateTable";
import ScoreTable from "../Components/scoreTable";

import '../CSS/Dashboard.css';

function Dashboard() {
    return(
      <div> 
        <h1>Dashboard Content</h1>
        <div className="dashboardLayout">
          <div className="columnOne"><DateTable /></div>
          <div className="columnTwo"><ScoreTable /></div>
        </div>
        
      </div>
    );    
  }

export default Dashboard;