import { BrowserRouter as Router, Route, Routes, useNavigate} from 'react-router-dom';
import Layout from './Layout';
import Dashboard from './Pages/Dashboard';
import Customers from './Pages/Customers';
import CustomerDetails from './Pages/CustomerDetails';
import {useEffect, useState, useContext} from 'react';
import { AuthContext } from './AuthContext';
import {doc, setDoc, getDoc, getDocs, collection} from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import {db, auth} from './firebase';
import { Link } from 'react-router-dom';

import Admin from './Pages/Admin';
import Profile from './Pages/Profile';


import './CSS/Login.css';
import './App.css';



function App() {

  const { user, login, logout } = useContext(AuthContext);

  const [username, setUsername] = useState(null);

  const [users, setUsers] = useState([]);

  const [loginInfo, setLoginInfo] = useState({
    username: '',
    password: ''
  });
  
  const changeHandler = (e) => {
    setLoginInfo({...loginInfo, [e.target.name]: e.target.value});
  }

  const [loginFailed, setLoginFailed] = useState(false);

  const handleLogin = () => {
    const {username, password} = loginInfo;
    if (login(username, password)) {
      setUsername(username);
      setLoginFailed(false);
    } else {
      setLoginFailed(true);
    }
  };

  const handleLogout = () => {
    logout();
  };

  const handleSetName = async () => {
    //Close popup
    setShowNamePopup(false);

    //Update the name in the firebase user document
    const userRef = doc(db, "users", user.uid);
    await setDoc(userRef, {
      name: newUserName
    }, {merge: true});
  };




  const [showNamePopup, setShowNamePopup] = useState(false);
  const [newUserName, setNewUserName] = useState('');

  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
        console.log("Auth state changed: ", user);
        if (user) {
            const userRef = doc(db, "users", user.uid);
            
            // First, check if the user document already exists
            const docSnapshot = await getDoc(userRef);

            const userData = docSnapshot.data();
            if (userData){
              setUserRole(userData.role);
            }

            // If the document does not exist, it's a new user
            if (!docSnapshot.exists()) {
                await setDoc(userRef, {
                    uid: user.uid,
                    email: user.email,
                    name: null,  // Set name to null for new users
                    role: "user"
                });

            setShowNamePopup(true);
            } else {
              const userData = docSnapshot.data();
                //If the user document exists, don't overwrite the name
                await setDoc(userRef, {
                    uid: user.uid,
                    email: user.email
                }, { merge: true });

                if (userData.name === null) {
                  setShowNamePopup(true);
                }
            }
        }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
        const usersCollection = collection(db, 'users');
        const usersSnapshot = await getDocs(usersCollection);
        const userList = usersSnapshot.docs.map(doc => doc.data());
        setUsers(userList);
    };

    fetchData();
  }, []);

  if (!user) {
    return(
      <div className="login-container">
        <div className="login-form">
          <h1>Please Log In</h1>
          <input type="email" name="username" placeholder="Your Email" onChange={changeHandler}></input>
          <input type="password" name="password" placeholder="Your Password" onChange={changeHandler}></input>
          <button onClick={handleLogin}>Log In</button>
          {loginFailed && <p>Username and password incorrect</p>}
        </div>
      </div>
    );
  }


  return (

    <>
    {showNamePopup && (
      <div className="name-popup">
        <div className="name-popup-inner">
          <h2>Hello, what is your name?</h2>
          <input type="text" value={newUserName} onChange={(e) => setNewUserName(e.target.value)} />
          <button onClick={handleSetName}>Submit</button>
        </div>
      </div>
    )}

    <Router>
      <Layout>
        <button onClick={handleLogout} style={{float:'right'}}>Log Out</button>
        {userRole === 'admin' && <Link to="/Admin"><button style={{float:'right'}} className="adminButton">Admin</button></Link>}
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/customer/:name" element={<CustomerDetails username={username} userRole={userRole}/>} />
          <Route path="/profile" element={<Profile />} />
          {userRole === 'admin' && <Route path="/admin" element={<Admin />} />}
        </Routes>
      </Layout>
    </Router>
  </>

  );
}

export default App;
