import React from 'react';
import { useEffect, useState } from 'react';
import { collection, getDocs, doc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';


const Admin = () => {

    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
          const userCollection = collection(db, 'users');
          const userSnapshot = await getDocs(userCollection);
          const userList = userSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
          setUsers(userList);
        };
      
        fetchData();
    }, []);

    const handleRoleChange = async (uid, newRole) => {
        const userRef = doc(db, 'users', uid);
        await updateDoc(userRef, {
          role: newRole
        });
      };


      return (
        <div>
          <h1>Admin Controls</h1>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <tr key={user.id}>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>
                    <select
                      value={user.role}
                      onChange={(e) => handleRoleChange(user.id, e.target.value)}
                    >
                      <option value="user">User</option>
                      <option value="admin">Admin</option>
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
      
};

export default Admin;