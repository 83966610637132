import React, { useEffect, useState } from 'react';
import { getDocs, collection } from 'firebase/firestore';
import { db } from '../firebase';
import { Link } from 'react-router-dom';

export default function DateTable() {
  const [allCustomers, setAllCustomers] = useState([]);
  const [sortField, setSortField] = useState('startDate'); // Initialize sort field
  const [sortDirection, setSortDirection] = useState('asc');  // 'asc' for ascending, 'desc' for descending

  useEffect(() => {
    const fetchData = async () => {
      const fetchedCustomers = await fetchAllCustomers();
      setAllCustomers(fetchedCustomers);
    };

    fetchData();
  }, []);

  const fetchAllCustomers = async () => {
    const querySnapshot = await getDocs(collection(db, 'customers'));
    return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  };

  // Update the sorting logic
  const sortedCustomers = [...allCustomers].sort((a, b) => {
    const aValue = sortField === 'renewDate' ? new Date(a.startDate.seconds * 1000).setFullYear(new Date(a.startDate.seconds * 1000).getFullYear() + 1) : a[sortField].seconds;
    const bValue = sortField === 'renewDate' ? new Date(b.startDate.seconds * 1000).setFullYear(new Date(b.startDate.seconds * 1000).getFullYear() + 1) : b[sortField].seconds;
    return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
  });

  const toggleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  return (
    <div>
      <h2>Renewal Date</h2>
      <table className="dashboardTable">
        <thead>
            <tr>
                <th>Customer Name</th>
                <th onClick={() => toggleSort('startDate')}>
                    Start Date {sortField === 'startDate' ? (sortDirection === 'asc' ? '▲' : '▼') : '△'}
                </th>
                <th onClick={() => toggleSort('renewDate')}>
                    Renew Date {sortField === 'renewDate' ? (sortDirection === 'asc' ? '▲' : '▼') : '△'}
                </th>
                <th>Months Left</th>
            </tr>
        </thead>
        <tbody>
          {sortedCustomers.map((customer) => {
            const startDate = new Date(customer.startDate.seconds * 1000);
            const currentDate = new Date();
            const renewDate = new Date(startDate);
            renewDate.setFullYear(startDate.getFullYear() + 1);

            const monthsDifference = Math.floor((renewDate - currentDate) / (1000 * 60 * 60 * 24 * 30));
            const monthsLeft = monthsDifference >= 0 ? `${monthsDifference} months` : `${-monthsDifference} months past renewal`;

            let color;
            if (monthsDifference > 6) {
              color = 'green';
            } else if (monthsDifference <= 6 && monthsDifference > 3) {
              color = '#0ec414';
            } else if (monthsDifference <= 3 && monthsDifference > 0) {
              color = 'orange';
            } else {
              color = 'red';
            }

            return (
              <tr key={customer.id}>
                <Link to={`/customer/${customer.name}`}><td>{customer.name}</td></Link>
                <td>{startDate.toLocaleDateString()}</td>
                <td>{renewDate.toLocaleDateString()}</td>
                <td style={{ color }}>{monthsLeft}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
