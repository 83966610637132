import React from 'react';
import { Bar } from 'react-chartjs-2';

const CustomerExpensesChart = ({ expenses, monthlyBudget }) => {
  // Initialize an object to store summed expenses for each month
  const monthlyExpenses = {};

  // Group expenses by month
  expenses.forEach((expense) => {
    const date = new Date(expense.date);
    const monthYear = `${date.getMonth() + 1}-${date.getFullYear()}`;
    
    if (monthlyExpenses[monthYear]) {
      monthlyExpenses[monthYear] += parseFloat(expense.value);
    } else {
      monthlyExpenses[monthYear] = parseFloat(expense.value);
    }
  });

  const labels = Object.keys(monthlyExpenses);
  const expenseData = Object.values(monthlyExpenses);
  
  // Create an array filled with the monthly budget value, same length as labels
  const incomeData = Array(labels.length).fill(monthlyBudget);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'Monthly Expenses',
        data: expenseData,
        backgroundColor: '#d12819',
        borderColor: '#d12819',
        borderWidth: 1,
        hoverBackgroundColor: '#141414',
      },
      {
        label: 'Monthly Income',
        data: incomeData,
        backgroundColor: '#22b31b',
        borderColor: '#22b31b',
        borderWidth: 1,
        hoverBackgroundColor: '#141414',
      },
    ],
  };

  return (
    <div>
      <Bar data={chartData} />
    </div>
  );
};

export default CustomerExpensesChart;
