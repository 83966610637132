import React, { useEffect, useState, useContext } from 'react';
import { collection, getDocs, getDoc, addDoc, doc, deleteDoc, updateDoc, arrayUnion, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../AuthContext';




//Import Charts
import CustomerScoreDoughnut from '../Charts/CustomerScoreDoughnut';

//Import CSS
import '../CSS/CustomerDetails.css';
import CustomerExpensesChart from '../Charts/CustomerExpensesChart';


const fetchCustomerDetailsByName = async (name) => {
  console.log("Fetching customer details by name");
    // Replace this code with the actual query to get the customer by name
    const querySnapshot = await getDocs(collection(db, 'customers'));
    const customerDoc = querySnapshot.docs.find((doc) => doc.data().name === name);


    if (customerDoc) {
      return { id: customerDoc.id, ...customerDoc.data() };
    }
    return null;
  };

  const fetchWorkLogs = async (customerId) => {
    console.log("Fetching work logs");
    const querySnapshot = await getDocs(collection(db, 'customers', customerId, 'workLogs'));
    return querySnapshot.docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });
  };

  
  

function CustomerDetails({username, userRole}) {

  const [hasFetchedDetails, setHasFetchedDetails] = useState(false);

  const [updateStatus, setUpdateStatus] = useState(null); //Handle error status for retrieving keywords



    const { name, customerId } = useParams();
    const [customer, setCustomer] = useState(null);

    const [expenses, setExpenses] = useState([]); 
    const [newExpense, setNewExpense] = useState({ type: '', date: '', value: '', vendor: '', domainAuthority: '', additionalInfo: '', numberOfCitations: '', author: '', amountOfWords: '', softwareType: '' });

    const [editingExpense, setEditingExpense] = useState(null);

    const [activeTab, setActiveTab] = useState('details');

    const [showAddExpensePopup, setShowAddExpensePopup] = useState(false);
    const [selectedExpenseType, setSelectedExpenseType] = useState(null);

    const [showAddWorkLogPopup, setShowAddWorkLogPopup] = useState(false);

    const [editingWorkLog, setEditingWorkLog] = useState(null);


    const [newKeyword, setNewKeyword] = useState("");

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [newWorkLogForm, setNewWorkLogForm] = useState({
      blogs: '',
      pagesAndOptimising: '',
      technicalChanges: '',
      healthScore: '',
      date: '',
      notes: ''
    });

    const [showViewWorkLogPopup, setShowViewWorkLogPopup] = useState(false);
    const [selectedWorkLog, setSelectedWorkLog] = useState(null);

    const { user } = useContext(AuthContext);

    const [usernameFromContext, setUsernameFromContext] = useState(null);

    const [allUsers, setAllUsers] = useState([]);

    useEffect(() => {
      const fetchAllUsers = async () => {
        console.log("Fetching all users (fetchAllUsers)")
        const querySnapshot = await getDocs(collection(db, 'users'));
        const allUsersData = querySnapshot.docs.map(doc => doc.data().name); // assuming 'name' field exists
        setAllUsers(allUsersData);
      };

      fetchAllUsers();
    }, []);

    const checkKeywords = async () => {
      if (customer && customer.keywords) {
          try {
              // Define the URL of your Cloud Function, replacing 'your-region' and 'your-project-id' with your own values
              const functionUrl = `https://us-central1-fs-crm-ed0f1.cloudfunctions.net/updateCustomerKeywords?customerId=${customerId}`;
              
              // Make a POST request to the Cloud Function, sending the keywords as the request body
              const response = await fetch(functionUrl, {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                      keywords: customer.keywords,
                  }),
              });
              
              // Check for a successful response
              if (!response.ok) {
                  throw new Error('Network response was not ok ' + response.statusText);
              }
              
              // Parse the updated keywords from the response
              const updatedKeywords = await response.json();
              
              // Update Firestore and local state with the updated keywords
              const customerRef = doc(db, 'customers', customer.id);
              await updateDoc(customerRef, { keywords: updatedKeywords });
              setCustomer({ ...customer, keywords: updatedKeywords });
          } catch (e) {
              console.error('Error updating keywords:', e);
          }
      } else {
          console.error("Customer or keywords are not available");
      }
  };
  

    // add this state variable
    const [isEditing, setIsEditing] = useState(false);

    const adminEditWorkLog = (workLog) => {
      setEditingWorkLog(workLog);  // Set the work log being edited
      setNewWorkLogForm(workLog);  // Populate the form fields
      if (userRole === 'admin') {
        setIsEditing(true);  // Set editing to true only if user is an admin
      }
      setShowAddWorkLogPopup(true);  // Open the popup
    };

    


    const fetchUsername = async (uid) => {
      console.log(`Fetching username for user ID: ${uid}`);
      const userDoc = await getDoc(doc(db, 'users', uid));
      if (userDoc.exists()) {
        setUsernameFromContext(userDoc.data().name);  // Set the 'name' field to your state variable
      } else {
        console.error('No such user!');
      }
    };
  
    // Fetch the username when the component mounts
    useEffect(() => {
      if (user) {
        fetchUsername(user.uid);
      }
      
      // ... Existing useEffect code
    }, [name, customer, user]);  // Added an empty dependency array to run only once on mount

    const handleWorkLogFormChange = (e) => {
      const { name, value } = e.target;
      setNewWorkLogForm({ ...newWorkLogForm, [name]: value });
    };

    const viewWorkLog = (workLog) => {
      setSelectedWorkLog(workLog);
      setShowViewWorkLogPopup(true);
    };

    const setDateRangePreset = (preset) => {
      const today = new Date();
      let newStartDate = new Date();
    
      switch (preset) {
        case 'lastMonth':
          newStartDate.setMonth(today.getMonth() - 1);
          break;
        case 'last3Months':
          newStartDate.setMonth(today.getMonth() - 3);
          break;
        case 'last6Months':
          newStartDate.setMonth(today.getMonth() - 6);
          break;
        default:
          break;
      }
    
      setStartDate(newStartDate.toISOString().split('T')[0]);
      setEndDate(today.toISOString().split('T')[0]);
    };
    

    //Work Log
    const [workLogs, setWorkLogs] = useState([]);  // New state for work logs

    const addWorkLog = async (username) => {
      if (customer && customer.id) {
        const completeWorkLog = { ...newWorkLogForm, user: username };
    
        // If we are editing, update the existing work log
        if (editingWorkLog && editingWorkLog.id) {
          try {
            await setDoc(doc(db, 'customers', customer.id, 'workLogs', editingWorkLog.id), completeWorkLog);
            setWorkLogs(workLogs.map(wl => wl.id === editingWorkLog.id ? { ...completeWorkLog, id: editingWorkLog.id } : wl));
          } catch (error) {
            console.error("Error updating document: ", error);
          }
        } else {
          // If we are not editing, add a new work log
          try {
            const docRef = await addDoc(collection(db, 'customers', customer.id, 'workLogs'), completeWorkLog);
            setWorkLogs([...workLogs, { ...completeWorkLog, id: docRef.id }]);
          } catch (error) {
            console.error("Error adding document: ", error);
          }
        }
    
        // Reset the form and close the popup
        setNewWorkLogForm({
          blogs: '',
          pagesAndOptimising: '',
          technicalChanges: '',
          healthScore: '', // Reset to the initial state, which could be null, 0, or ''
          date: ''
        });
        setEditingWorkLog(null);
        setShowAddWorkLogPopup(false);

        // Reset the form and close the popup
        setIsEditing(false);  // Reset editing to false

      } else {
        console.error("Customer ID is not available");
      }
    };

    
    

    const addKeyword = async () => {
      if (customer && customer.id) {
        const customerRef = doc(db, 'customers', customer.id);
        try {
          await updateDoc(customerRef, {
            keywords: arrayUnion({ keyword: newKeyword, position: 0 }) // Add new keyword with initial position 0
          });
          // Update local state
          setCustomer({
            ...customer,
            keywords: [...customer.keywords, { keyword: newKeyword, position: 0 }]
          });
          setNewKeyword("");  // Reset the new keyword input
        } catch (e) {
          console.error('Error adding keyword:', e);
        }
      }
    };

    useEffect(() => {
      const fetchData = async () => {
        // Only proceed with fetching if 'hasFetchedDetails' is false
        if (!hasFetchedDetails) {
          // Fetch customer details
          const fetchedCustomer = await fetchCustomerDetailsByName(name);
          if (fetchedCustomer) {
            setCustomer(fetchedCustomer);
    
            // Fetch work logs if customer ID is available
            if (fetchedCustomer.id) {
              const fetchedWorkLogs = await fetchWorkLogs(fetchedCustomer.id);
              setWorkLogs(fetchedWorkLogs);
            }
    
            // Fetch expenses details if customer ID is available
            const querySnapshot = await getDocs(collection(db, 'customers', fetchedCustomer.id, 'expenses'));
            const expensesData = querySnapshot.docs.map((doc) => {
              return { id: doc.id, ...doc.data() };
            });
            setExpenses(expensesData);
    
            // Update 'hasFetchedDetails' to prevent further fetching in this session
            setHasFetchedDetails(true);
          }
        }
      };
    
      // Call the fetchData function
      fetchData();
    }, [name, hasFetchedDetails]);  // Removed 'customer' from dependency array
    


    const addExpense = async () => {
      if (customer && customer.id && selectedExpenseType) {

        const completeNewExpense = { ...newExpense, type: selectedExpenseType };


        // If editing, update the Firestore document and the state
        if (editingExpense && editingExpense.id) {
          try {
            await setDoc(doc(db, 'customers', customer.id, 'expenses', editingExpense.id), newExpense);
            setExpenses(expenses.map(exp => exp.id === editingExpense.id ? { ...newExpense, id: editingExpense.id } : exp));
          } catch (error) {
            console.error("Error updating document: ", error);
          }
        } else {
          // If not editing, add a new Firestore document and update the state
          try {
        const docRef = await addDoc(collection(db, 'customers', customer.id, 'expenses'), completeNewExpense);
        setExpenses([...expenses, { ...completeNewExpense, id: docRef.id }]);
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    }

    setNewExpense({ type: '', date: '', value: '' });  // Reset the form
    setEditingExpense(null);  // Exit editing mode
    setSelectedExpenseType(null);  // Reset selected expense type
    setShowAddExpensePopup(false);  // Close the popup
  } else {
    console.error("Customer ID or selected expense type is not available");
  }
};
    
    

    const deleteExpense = async (expense) => {
      // Log the ID for debugging
      console.log("Deleting expense with ID:", expense.id);
      
      if (customer && customer.id && expense.id) {
        try {
          // Delete from Firestore
          await deleteDoc(doc(db, 'customers', customer.id, 'expenses', expense.id));
          
          // Delete from local state
          setExpenses(expenses.filter(exp => exp.id !== expense.id));
          
          console.log(`Successfully deleted expense with ID: ${expense.id}`);
        } catch (error) {
          console.error("Error deleting document: ", error);
        }
      } else {
        console.error("Customer ID or Expense ID is not available");
      }
    };
    
    
    const editExpense = (expense) => {
      setEditingExpense(expense);
      setNewExpense(expense);
      setSelectedExpenseType(expense.type); // Make sure to set this
      setShowAddExpensePopup(true);
    };
    

    const showMoreInfo = (expense) => {
      setNewExpense(expense);
    };

    const setMonth = (month) => {
      const today = new Date();
      const year = today.getFullYear();
      const newStartDate = new Date(year, month, 1);  // First day of the month
      const newEndDate = new Date(year, month + 1, 0);  // Last day of the month
    
      setStartDate(newStartDate.toISOString().split('T')[0]);
      setEndDate(newEndDate.toISOString().split('T')[0]);
    };

    const editWorkLog = (workLog) => {
      setEditingWorkLog(workLog);  // Set the work log being edited
      setNewWorkLogForm(workLog);  // Populate the form fields
      setShowAddWorkLogPopup(true);  // Open the popup
    };


    const deleteWorkLog = async (workLog) => {
      if (customer && customer.id && workLog.id) {
        try {
          // Delete from Firestore
          await deleteDoc(doc(db, 'customers', customer.id, 'workLogs', workLog.id));
          // Delete from local state
          setWorkLogs(workLogs.filter(wl => wl.id !== workLog.id));
        } catch (error) {
          console.error("Error deleting work log: ", error);
        }
      } else {
        console.error("Customer ID or Work Log ID is not available");
      }
    };
    


    //Code for tabs
    const renderActiveTab = () => {
      if (activeTab === 'keywords') {
        return(
          <>
            <h2>Keywords</h2>
            <button onClick={checkKeywords}>Check Keywords</button>
            <input 
              type="text" 
              value={newKeyword} 
              placeholder="New Keyword" 
              onChange={(e) => setNewKeyword(e.target.value)} 
            />
            <button onClick={addKeyword}>Add Keyword</button>
              <table>
                <thead>
                  <tr>
                    <th>Keyword</th>
                    <th>Position</th>
                  </tr>
                </thead>
                <tbody>
                  {customer.keywords.map((keywordObj, index) => (
                    <tr key={index}>
                      <td>{keywordObj.keyword}</td>
                      <td>{keywordObj.position}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
        );
      }

      if (activeTab === 'expenses'){
        return(
          <>
            <h2>Expenses</h2>
            <div>
              <button onClick={() => setShowAddExpensePopup(true)}>Add New Expense</button>
            </div>

            <table>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Date</th>
                  <th>Value</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                {expenses.map((expense, index) => (
                  <tr key={index}>
                    <td>{expense.type}</td>
                    <td>{expense.date}</td>
                    <td>£{expense.value}</td>
                    <td>
                      <button onClick={() => editExpense(expense)}>Edit</button>
                      <button onClick={() => deleteExpense(expense)}>Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

          </>
          
        );
      }




      //Tab for Work
      if (activeTab === 'work'){
        return(
          <>
            <h2>Work Logs</h2>
              <button onClick={() => setShowAddWorkLogPopup(true)}>Add New Work Log</button>

              {/* Date filter */}
              <div>
                <input type="date" onChange={(e) => setStartDate(e.target.value)} placeholder="Start Date" />
                <input type="date" onChange={(e) => setEndDate(e.target.value)} placeholder="End Date" />
                <button onClick={() => setDateRangePreset('lastMonth')}>Last Month</button>
                <button onClick={() => setDateRangePreset('last3Months')}>Last 3 Months</button>
                <button onClick={() => setDateRangePreset('last6Months')}>Last 6 Months</button>
                <div>
                  <select onChange={(e) => setMonth(parseInt(e.target.value))}>
                    <option value="">Select Month</option>
                    <option value="0">January</option>
                    <option value="1">February</option>
                    <option value="2">March</option>
                    <option value="3">April</option>
                    <option value="4">May</option>
                    <option value="5">June</option>
                    <option value="6">July</option>
                    <option value="7">August</option>
                    <option value="8">September</option>
                    <option value="9">October</option>
                    <option value="10">November</option>
                    <option value="11">December</option>
                  </select>
                </div>
              </div>


              <table>
                <thead>
                  <tr>
                    <th>Work Type</th>
                    <th>Date</th>
                    <th>User</th>
                    <th>Controls</th>
                  </tr>
                </thead>

                <tbody>
                  {workLogs.filter(workLog => {
                    const workLogDate = new Date(workLog.date);
                    return (!startDate || new Date(startDate) <= workLogDate) && (!endDate || new Date(endDate) >= workLogDate);
                  }).map((workLog, index) => (
                    <tr key={index}>
                      <td>Update</td> {/* Changed from workLog.workType to "Update" */}
                      <td>{workLog.date}</td>
                      <td>{typeof workLog.user === 'object' ? 'Unknown' : workLog.user}</td>
                      <td>
                        <button onClick={() => viewWorkLog(workLog)}>View</button>
                        {userRole === 'admin' && (
                          <>
                            <button onClick={() => adminEditWorkLog(workLog)} className="adminButton">Edit</button>
                            <button onClick={() => deleteWorkLog(workLog)} className="adminButton">Delete</button>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {isEditing && userRole === 'admin' && (
                <select 
                  name="user" 
                  value={newWorkLogForm.user}
                  onChange={handleWorkLogFormChange}
                >
                  {allUsers.map((user, index) => (
                    <option key={index} value={user}>{user}</option>
                  ))}
                </select>
              )}

              {showViewWorkLogPopup && selectedWorkLog && (
                <div className="infoPopup">
                  <div className="infoPopupContent">
                    <h3>Work Log Details</h3>
                    <p><span class="workLogViewHeaders">Date:</span><br /> {selectedWorkLog.date}</p>
                    <p><span class="workLogViewHeaders">User:</span> {typeof selectedWorkLog.user === 'object' ? 'Unknown' : selectedWorkLog.user}</p>
                    <p><span class="workLogViewHeaders">Blogs:</span></p>
                    {selectedWorkLog.blogs ? <p>{selectedWorkLog.blogs.split('\n').map((line, index) => (
                      <React.Fragment key={index}>{line}<br /></React.Fragment>
                    ))}</p> : <p>None</p>}
                    <p><span class="workLogViewHeaders">Pages & Optimising:</span></p>
                    {selectedWorkLog.pagesAndOptimising ? <p>{selectedWorkLog.pagesAndOptimising.split('\n').map((line, index) => (
                      <React.Fragment key={index}>{line}<br /></React.Fragment>
                    ))}</p> : <p>None</p>}
                    <p><span class="workLogViewHeaders">Technical Changes:</span></p>
                    {selectedWorkLog.technicalChanges ? <p>{selectedWorkLog.technicalChanges.split('\n').map((line, index) => (
                      <React.Fragment key={index}>{line}<br /></React.Fragment>
                    ))}</p> : <p>None</p>}
                    <p><span class="workLogViewHeaders">Notes:</span></p>
                    {selectedWorkLog.notes ? (
                      <p>{selectedWorkLog.notes.split('\n').map((line, index) => (
                        <React.Fragment key={index}>{line}<br /></React.Fragment>
                      ))}</p>
                    ) : (
                      <p>None</p>
                    )}

                    <p><span class="workLogViewHeaders">Health Score:</span> {selectedWorkLog.healthScore || 'None'}</p>
                    <button onClick={() => setShowViewWorkLogPopup(false)}>Close</button>
                  </div>
                </div>



              )}

              

          </>
          
        );
      }

      /*Notes section*/
      if (activeTab === 'notes'){
        return(
          <h2>Notes</h2>
        )
      }
    }







  if (!customer) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>{customer.name}</h1>
      
      <div className="customerLayout">
        <div className="left">
          <h2>Details</h2>
          <span className="title">Start Date:</span><span> {new Date(customer.startDate.seconds * 1000).toLocaleDateString()}</span><br />
          <span className="title">Monthly Budget: </span><span>{customer.monthlyBudget}</span><br />
          <span className="title">URL:</span><span> {customer.url}</span><br />
          <span className="title">Score:</span> <span>{customer.score} /100</span>
        </div>
        <div className="middle">
          <h2>Customer Expenses</h2>
          <CustomerExpensesChart expenses={expenses} monthlyBudget={customer.monthlyBudget}/>
        </div>
        <div className="right">
          <div className="scoreChart">
            <h2>Customer Score</h2>
            <CustomerScoreDoughnut score={customer.score}/>
          </div>
        </div>
      </div>

      
      <div>
        <button onClick={() => setActiveTab('keywords')}>Keywords</button>
        <button onClick={() => setActiveTab('expenses')}>Expenses</button>
        <button onClick={() => setActiveTab('work')}>Work Log</button>
        <button onClick={() => setActiveTab('notes')}>Notes</button>
      </div>

      {renderActiveTab()}

      {showAddWorkLogPopup && (
        <div className="infoPopup">
          <div className="infoPopupContent">
            
            <h3>Add New Work Log</h3>
            
            <label>
            <span class="workLogViewHeaders">Blogs: </span>
              <textarea
                class="textareaClass" 
                name="blogs" 
                placeholder="Blogs" 
                value={newWorkLogForm.blogs} 
                onChange={handleWorkLogFormChange} 
                rows={6}
              />
            </label>

            <label>
            <span class="workLogViewHeaders">Pages & Optimising: </span>
              <textarea 
                class="textareaClass"
                name="pagesAndOptimising" 
                placeholder="Pages & Optimising" 
                value={newWorkLogForm.pagesAndOptimising} 
                onChange={handleWorkLogFormChange} 
                rows={6}
              />
            </label>

            <label>
            <span class="workLogViewHeaders">Technical Changes: </span>
              <textarea
                class="textareaClass" 
                name="technicalChanges" 
                placeholder="Technical changes" 
                value={newWorkLogForm.technicalChanges} 
                onChange={handleWorkLogFormChange} 
                rows={6}
              />
            </label>

            <label>
            <span class="workLogViewHeaders">Health Score: </span>
              <input 
                type="number" 
                name="healthScore" 
                placeholder="Health Score" 
                value={newWorkLogForm.healthScore} 
                onChange={handleWorkLogFormChange} 
              />
            </label>

            <label>
              <span class="workLogViewHeaders">Additional Notes:</span>
              <textarea 
                className="textareaClass" 
                name="notes" 
                placeholder="Notes (additional remarks, observations, etc)" 
                value={newWorkLogForm.notes} 
                onChange={handleWorkLogFormChange} 
                rows={6}
              />
            </label>

            <label>
            <span class="workLogViewHeaders">Date: </span>
              <input 
                type="date" 
                name="date" 
                value={newWorkLogForm.date} 
                onChange={handleWorkLogFormChange} 
              />
            </label>
            {isEditing && userRole === 'admin' &&(
              <label>Change User: 
                <select name="user" value={newWorkLogForm.user} onChange={handleWorkLogFormChange} >
                  {allUsers.map((user, index) => (
                    <option key={index} value={user}>{user}</option>
                  ))}
                </select>
              </label>
            )}
            <button onClick={() => addWorkLog(isEditing && userRole === 'admin' ? newWorkLogForm.user : usernameFromContext)}>Save Work Log</button>
            <button onClick={() => setShowAddWorkLogPopup(false)}>Close</button>
          </div>
        </div>
      )}

      {showAddExpensePopup && (
        <div className="infoPopup">
          <div className="infoPopupContent">
            <h3>{editingExpense ? 'Edit Expense' : 'Add New Expense'}</h3>

            <label>
              Expense Type
              <select onChange={(e) => setSelectedExpenseType(e.target.value)} value={selectedExpenseType}>
                <option value="">Select an option</option>
                <option value="Backlink">Backlink</option>
                <option value="Citation">Citation</option>
                <option value="Content">Content</option>
                <option value="Software">Software</option>
                <option value="Other">Other</option>
              </select>
            </label>

            {selectedExpenseType && (
              <>
                <h4>{selectedExpenseType} Info</h4>
                <input type="date" value={newExpense.date} onChange={(e) => setNewExpense({ ...newExpense, date: e.target.value })} />
                <input type="number" placeholder="Value" value={newExpense.value} onChange={(e) => setNewExpense({ ...newExpense, value: e.target.value })} />

                {/* Fields for 'Backlink' */}
                {selectedExpenseType === 'Backlink' && (
                  <>
                    <input type="text" placeholder="Vendor" value={newExpense.vendor} onChange={(e) => setNewExpense({ ...newExpense, vendor: e.target.value })} />
                    <input type="text" placeholder="Domain Authority" value={newExpense.domainAuthority} onChange={(e) => setNewExpense({ ...newExpense, domainAuthority: e.target.value })} />
                    <input type="text" placeholder="Additional Info" value={newExpense.additionalInfo} onChange={(e) => setNewExpense({ ...newExpense, additionalInfo: e.target.value })} />
                  </>
                )}

                {/* Fields for 'Citation' */}
                {selectedExpenseType === 'Citation' && (
                  <>
                    <input type="text" placeholder="Vendor" value={newExpense.vendor} onChange={(e) => setNewExpense({ ...newExpense, vendor: e.target.value })} />
                    <input type="number" placeholder="Number of Citations" value={newExpense.numberOfCitations} onChange={(e) => setNewExpense({ ...newExpense, numberOfCitations: e.target.value })} />
                    <input type="text" placeholder="Additional Info" value={newExpense.additionalInfo} onChange={(e) => setNewExpense({ ...newExpense, additionalInfo: e.target.value })} />
                  </>
                )}

                {/* Fields for 'Content' */}
                {selectedExpenseType === 'Content' && (
                  <>
                    <input type="text" placeholder="Author" value={newExpense.author} onChange={(e) => setNewExpense({ ...newExpense, author: e.target.value })} />
                    <input type="number" placeholder="Amount of Words" value={newExpense.amountOfWords} onChange={(e) => setNewExpense({ ...newExpense, amountOfWords: e.target.value })} />
                    <input type="text" placeholder="Additional Info" value={newExpense.additionalInfo} onChange={(e) => setNewExpense({ ...newExpense, additionalInfo: e.target.value })} />
                  </>
                )}

                {/* Fields for 'Software' */}
                {selectedExpenseType === 'Software' && (
                  <>
                    <input type="text" placeholder="Type" value={newExpense.softwareType} onChange={(e) => setNewExpense({ ...newExpense, softwareType: e.target.value })} />
                  </>
                )}

                <button onClick={addExpense}>Save Expense</button>
              </>
            )}
          <button onClick={() => {
            setShowAddExpensePopup(false);
            setEditingExpense(null); // Reset editing state
          }}>
            Close
          </button>
        </div>
      </div>
    )}

      
    </div>

  );
}

export default CustomerDetails;
